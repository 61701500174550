export default {
  common: {
    centerName: 'Research Center for Life Science Computing at Zhejiang Lab',
    tryButton: 'Try it Now',
    copySuccess: 'Copied Successfully!',
    copyFail: 'Copy Failed!'
  },
  navigator: {
    researchDirection: 'Research Directions',
    coreBenefits: 'Core Strengths',
    aboutUs: 'About Us',
    massiveDataset: 'Massive Datasets',
    computingTools: 'Diverse Computing Tools',
    largeModel: 'Multimodal AI Large Models',
    scientificExploration1: 'Scientific Exploration Accelerator',
    scientificExploration2: ': ',
    scientificExploration3: 'Visualization Service Cloud Platform',
    result: 'Publications and Patents',
    partner: 'Partners',
    contact: 'Contact Us',
    copyRight: '版权'
  },
  carousel: {
    carouselContent1: {
      title: 'Open Platform for Life Science Computing',
      descPc: 'We offer a one-stop cloud-intelligence integrated product solution by leveraging the fusion of IT (computational science) and BT (biotechnology). Relying on high-quality wet lab datasets and advanced algorithms, and supported by a comprehensive digital platform and R&D system, we continuously develop more efficient, faster, and more accurate computational tools to drive industrial advancement.',
      descMobile: 'Utilizing the integration of IT (computational science) and BT (biotechnology) to provide a one-stop cloud-intelligence product solution.',
      button: 'Try it now'
    },
    carouselContent2: {
      title: "Latest Achievement: “Tristate Gate” Genetic Circuit Enables Intelligent Computing in Cells",
      descPc: 'Wang Hui from the Research Center for Life Science Computing at Zhejiang Lab, as a co-corresponding author, collaborated with a joint research team from Zhejiang University, National University of Defense Technology, and Westlake University to publish a research paper titled “Multi-layered Computational Gene Networks by Engineered Tristate Logics” in <i>Cell</i>.',
      descMobile: 'Wang Hui as a co-corresponding author from the Research Center for Life Science Computing at Zhejiang Lab, published research findings in <i>Cell</i>.',
      button: 'View Details'
    }
  },
  researchDirection: {
    direction1: 'Disease Diagnosis and Treatment',
    direction2: 'Human Simulation',
    direction3: 'Gene Therapy',
    direction4: 'Functional Protein R&D',
    direction5: 'Nucleic Acid Testing',
    direction6: 'Disease Target Regulation',
    researchBaseName1: {
      name: 'Large Models',
      desc: 'Designing pre-trained models with high generalization capabilities to enhance the accuracy of multimodal life science data characterization, autonomously achieving scientific discoveries through agent-based technology.'
    },
    researchBaseName2: {
      name: 'Data',
      desc: 'Achievinge standardization, intelligent integration, efficient circulation, and secure control of multi-source and multi-modal life science data'
    },
    researchBaseName3: {
      name: 'Computional Toolkit',
      desc: 'Developing innovative computational methods to screen, generate, and optimize biomolecules, and to build a digital twin system of the human body.'
    },
    centerDogma: 'Material Basis and Regulation of the Central Dogma',
    gotIt: 'Got It'
  },
  massiveDataset: {
    sequence: {
      title: 'Sequence Data',
      desc1: 'Analyzing DNA, RNA and protein sequences to investigate their biological functions',
      unit: 'Million',
      desc2: 'Protein Sequence',
      desc3: 'Data Scale'
    },
    omics: {
      title: 'Omics Data',
      desc1: 'Training data covering genomics, transcriptomics, proteomics, metabolomics, epigenetics data, mutation data and other multi-omics data,',
      desc2: 'including nucleic acid and protein sequences from nearly a hundred species.'
    },
    interaction: {
      title: 'Interaction Data',
      desc1: 'Covering known and predicted interactions between proteins, nucleic acids, and small molecules.',
      desc2: 'These interactions include direct (physical) and indirect (functional) associations derived from computational predictions, cross-species knowledge transfer, and data aggregated from other publicly databases.',
      desc3: 'Involves protein and gene interactions, chemical interactions, and post-translational modifications in major model organisms.'
    },
    literature: {
      title: 'Literature Data',
      desc1: 'Tracking and monitoring cutting-edge developments in life sciences',
      desc2: 'Building a comprehensive pipeline for literature information extraction',
      desc3: 'Enhancing information on protease experimental activities',
      desc4: 'Efficiently supporting scientific research'
    }
  },
  computingTools: {
    protein: {
      name1: 'Protein Structure Prediction',
      name2: 'Protein Sequence Optimization',
      name3: 'Binding Site Prediction',
      name4: 'High-Throughput Screening'
    },
    gene: {
      name1: 'Phylogenetic Tree of Nucleic Acid Sequence',
      name2: 'RNA Structure Prediction'
    },
    protogene: {
      name1: 'Protein-Protein Interaction Prediction',
      name2: 'ncRNA-Protein Interaction Prediction'
    },
    sgrna: {
      name1: 'Intelligent sgRNA Design'
    },
  },
  largeModel: {
    sequence: {
      title: 'Sequence Pre-trained Large Model',
      desc1: 'Protein Language Large Model',
      desc2: 'Biological Macromolecular Sequences'
    },
    omics: {
      title: 'Omics Pre-trained Large Model',
      desc: 'Genomics, Transcriptomics, Proteomics, Metabolomics, and Spatial Omics'
    },
    interact: {
      title: 'Interactive Pre-trained Large Model',
      desc: 'Protein-Small Molecule Interactions to Guide Protein Engineering and Drug Design'
    },
  },
  scientificExploration: {
    one:{
      title: 'A platform that better understands the scientists\' needs',
      desc: 'Focusing on scientists and bioinformatics analysts, providing customized development services to universities, enterprises, and research institutions.',
    },
    two: {
      title: 'Combining data sharing and privacy protection',
      desc: 'With a carefully designed permission management system, this data platform allows secure data sharing between different organizations and teams without physical data transfer, while ensuring data privacy.',
    },
    three: {
      title: 'AI and big data-driven, precision prediction',
      desc: 'The platform comprises billions of protein sequences and massive omics data. Utilizing our self-developed foundational large models, it delivers more precise and effective predictions.',
    },
    four: {
      title: 'Visual and efficient, automated computational processing',
      desc: 'An online cloud service platform that allows you to customize tasks as needed and run them in the background, easily obtaining computational results.',
    }
  },
  result: {},
  partner: {
    desc: 'Partnering with top universities and innovative enterprises'
  },
  contact: {
    copyEmail: 'Click to copy email',
    add: 'Building 11, West Zone, Phase I, Zhejiang Lab, Kechuang Avenue, Zhongtai Sub-District, Yuhang District, Hangzhou, Zhejiang Province, China',
    toMap: 'Jump to Baidu map',
    learnMore: 'Follow the "Zhejiang Lab" official WeChat account for more information'
  },
  copyRight: {}
}