import { createI18n } from "vue-i18n";
import CN from "./cn";
import EN from "./en";

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: localStorage.getItem('lang') ?? 'CN', // 设置初始语言类型
  messages: {
    CN,
    EN
  }
});

export default i18n;