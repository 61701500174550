<template>
    <baidu-map 
      class="map" 
      :center="center" 
      :zoom="zoom" 
      :scroll-wheel-zoom="true"
      @ready="ready"
    >
      <bm-scale anchor="BMAP_ANCHOR_TOP_LEFT" :offset="{X:0}"></bm-scale>
    </baidu-map>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Map'
})
</script>


<script setup>
    const center = ref({
      lng: 119.902185,
      lat: 30.267588
    });

    let zoom = ref(15);


    const ready = ({BMap,map})=>{
    //  对地图进行自定义操作
    };
</script>

<style>
/* 地图容器必须设置宽和高属性 */
.map {
  width: 270px;
  height: 143px;
  @media screen and (max-width: 380px) {
    width: 200px;
  }
}
</style>