import { createApp } from "vue";
import { createPinia } from "pinia";
import './utils/rem.js';
import BaiduMap from 'vue-baidu-map-3x'
import App from "./App.vue";
import router from "./router";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'animate.css';
import '@/assets/fonts/font.css';
import i18n from "@/assets/lang";
// import store from "./store";
// import useUserStore from './store/user.js'

const app = createApp(App);
const pinia = createPinia();

app.use(pinia).use(i18n).use(router).use(ElementPlus).use(BaiduMap, {
    ak: "r5vkgEGwPFnet0ipUEAmf8QfkiuqlEPW"
}).mount("#app");
// const userInfo = useUserStore()
// const access_token = sessionStorage.getItem('access_token')
// if (!access_token) {
//   userInfo.doLogin()
// }

