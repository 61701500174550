<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<script setup>
import { ElConfigProvider } from 'element-plus'
import { onUnmounted, computed, onMounted } from 'vue'
import useDeviceStore from '@/store/device.js'

const device = useDeviceStore()

onMounted(() => {
  window.addEventListener('resize', device.setDeviceType, false)
})
onUnmounted(() => {
  window.removeEventListener('resize', device.setDeviceType, false)
})
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  // background-color: rgba(196, 239, 209, 0.6);
}

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
