<template>
  <div
    :class="
      scheme
        ? 'large-model-container-mobile large-model-container-mobile-light'
        : 'large-model-container-mobile large-model-container-mobile-dark'
    "
    @mouseenter="animateStep1 = true"
  >
    <TitlePanel 
      :title="$t('navigator.largeModel')" 
      titleEn="Multimodal AI Large Models" 
    />
    <div v-if="animateStep1" class="models-row-mobile">
      <div class="models-row-1-mobile">
        <div class="layer-0 models-col-mobile" :class="{ dark: !scheme }">
          <div class="layer-2 models-col-1-mobile">
            <img :src="sequence" alt="" />
          </div>
          <div class="layer-1 models-col-2-mobile">
            <span :class="{ dark: !scheme, en: lang !== 'CN' }">
              {{ $t('largeModel.sequence.title') }}
            </span>
            <span :class="{ dark: !scheme, en: lang !== 'CN' }">
              {{ $t('largeModel.sequence.desc1') }}<br/>{{ $t('largeModel.sequence.desc2') }}
            </span>
          </div>
        </div>

        <div class="layer-0 models-col-mobile" :class="{ dark: !scheme }">
          <div class="layer-2 models-col-1-mobile">
            <img :src="omics" alt="" />
          </div>
          <div class="layer-1 models-col-2-mobile">
            <span :class="{ dark: !scheme, en: lang !== 'CN' }">
              {{ $t('largeModel.omics.title') }}
            </span>
            <span :class="{ dark: !scheme, en: lang !== 'CN' }">
              {{ $t('largeModel.omics.desc') }}
            </span
            >
          </div>
        </div>

        <div class="layer-0 models-col-mobile" :class="{ dark: !scheme }">
          <div class="layer-2 models-col-1-mobile">
            <img :src="interact" alt="" />
          </div>
          <div class="layer-1 models-col-2-mobile">
            <span :class="{ dark: !scheme, en: lang !== 'CN' }">
              {{ $t('largeModel.interact.title') }}
            </span>
            <span :class="{ dark: !scheme, en: lang !== 'CN' }">
              {{ $t('largeModel.interact.desc') }}
            </span
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else class="models-row-empty-mobile"></div>
  </div>
</template>

<script>
import TitlePanel from '../../components/TitlePanel.vue'
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  computed,
  inject,
} from 'vue'

export default defineComponent({
  name: 'LargeModel',
  components: { TitlePanel },
})
</script>

<script setup>
const sequence = require('@/assets/img/largemodel/sequence.png')
const interact = require('@/assets/img/largemodel/interact.png')
const omics = require('@/assets/img/largemodel/omics.png')

const scheme = inject('globalScheme')
const lang = inject('globalLang')

const animateStep1 = ref(false)

const onScroll = () => {
  const showWidth =
    document.documentElement.clientWidth || document.body.clientWidth
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop

  if (showWidth < 400) {
    if (scrollTop > 2300 && scrollTop < 2900) {
      animateStep1.value = true
      window.removeEventListener('scroll', onScroll)
    }
  } else if (showWidth < 600) {
    if (scrollTop > 2300 && scrollTop < 3090) {
      animateStep1.value = true
      window.removeEventListener('scroll', onScroll)
    }
  } else {
    if (scrollTop > 2400 && scrollTop < 3300) {
      animateStep1.value = true
      window.removeEventListener('scroll', onScroll)
    }
  }
}

onMounted(() => {
  window.addEventListener('scroll', onScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>

<style scoped lang="less">
.large-model-container-mobile-light {
  background-color: #ffffff; // 浅色模式
}
.large-model-container-mobile-dark {
  background-color: #17171a; // 暗色模式
}
.large-model-container-mobile {
  padding: 48px 5% 56px;
  background-image: url('../../../assets/img/largemodel/bg_buttom_left.png'),
    url('../../../assets/img/largemodel/bg_top_right.png');
  background-size: 600px, 600px;
  background-position: left -240px bottom -320px, right -290px top -220px;
  background-repeat: no-repeat;
  overflow: hidden;
  @media screen and (max-width: 350px) {
    padding: 48px 1% 56px;
  }

  .models-row-empty-mobile {
    display: block;
    margin: 40px 0 0 0;
    height: 500px;
  }

  .models-row-mobile {
    display: block;
    margin: 40px 0 0 0;

    .models-row-1-mobile {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 500px;

      .models-col-mobile {
        display: flex;
        width: 306px;
        height: 128px;
        border: 1px solid #dbedff;
        border-radius: 16px;
        background: linear-gradient(112deg, #f7fbff 67%, #ecf5ff 99%);
        opacity: 1;
        &.dark {
          background: #282e3a;
          border: none;
        }
        @media screen and (max-width: 350px) {
          width: 206px;
          height: 98px;
        }

        .models-col-1-mobile {
          margin-left: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media screen and (max-width: 350px) {
            margin-left: 10px;
          }
        }

        .models-col-2-mobile {
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          @media screen and (max-width: 350px) {
            margin-left: 10px;
          }
        }

        img {
          height: 86px;
          width: 86px;
          @media screen and (max-width: 350px) {
            width: 43px;
            height: 43px;
          }
        }

        span {
          font-size: 20px;
          line-height: normal;
          text-align: left;
          width: 180px;
          opacity: 1;
          font-family: Alibaba-PuHuiTi-Bold;
          font-weight: 800;
          letter-spacing: 0em;
          color: #283464;
          font-variation-settings: 'opsz' auto;
          font-feature-settings: 'kern' on;
          z-index: 0;
          margin-bottom: 20px;
          &.en {
            font-size: 15px;
          }
          &.dark {
            color: #ffffff;
          }
          @media screen and (max-width: 350px) {
            width: 140px;
            font-size: 16px;
            margin-bottom: 10px;
            &.en {
              font-size: 13px;
            }
          }

          &:last-child {
            font-size: 12px;
            line-height: 18px;
            font-family: PingFang SC;
            font-weight: normal;
            color: #646566;
            margin-bottom: 0px;
            &.en {
              font-size: 10px;
            }
            &.dark {
              color: #ffffffb3;
            }
            @media screen and (max-width: 350px) {
              font-size: 10px;
              &.en {
                font-size: 8px;
              }
            }
          }
        }
      }
    }
  }

  .layer-0 {
    animation-name: anim-layer-0;
    animation-timing-function: linear;
    animation-duration: 600ms;
    animation-fill-mode: both;
    animation-delay: 0s;
  }

  .layer-1 {
    animation-name: anim-layer-1;
    animation-timing-function: linear;
    animation-duration: 500ms;
    animation-fill-mode: both;
    animation-delay: 100ms;
  }
  .layer-2 {
    animation-name: anim-layer-2;
    animation-timing-function: linear;
    animation-duration: 600ms;
    animation-fill-mode: both;
    animation-delay: 0s;
  }

  @keyframes anim-layer-0 {
    0% {
      transform: translateY(-90px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes anim-layer-1 {
    0% {
      transform: translateY(-90px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes anim-layer-2 {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}
</style>
