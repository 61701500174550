import { MOBILE_WIDTH } from './const'
// 设置 rem 函数, 默认字体大小16px
export function setRem () {
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  //得到html的Dom元素
  let htmlDom = document.getElementsByTagName('html')[0];
  //设置根元素字体大小
  let fontSize = 16
  if (htmlWidth < 1440 && htmlWidth > MOBILE_WIDTH) {
    fontSize = htmlWidth / 1440 * 16
  }
  htmlDom.style.fontSize = fontSize + 'px';
}
// // 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}