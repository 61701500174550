<template>
  <div :class="scheme? 'result-container-mobile result-container-mobile-light' : 'result-container-mobile result-container-mobile-dark'">
    <el-row>
      <TitlePanel 
        :title="$t('navigator.result')"
        titleEn="Publications and Patents"
      >
      </TitlePanel>
    </el-row>
    <el-row class="result-row-mobile">
      <el-col :span="24" class="result-col-mobile">
        <img class="paper" :src="paper" alt="paper">
      </el-col> 
      <el-col :span="24" class="result-col-mobile">
        <img class="patent" :src="patent" alt="patent">
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TitlePanel from '../../components/TitlePanel.vue'
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'Result',
  components: { TitlePanel }
})
</script>
  
<script setup>
  const paper = require('@/assets/img/result/paper.png')
  const patent = require('@/assets/img/result/patent.png')
  const scheme = inject('globalScheme')
</script>

<style scoped lang="less">
.result-container-mobile-light {
  background-color: #ffffff;
}
.result-container-mobile-dark {
  background-color: #17171A;
}
.result-container-mobile {
  padding: 48px 20% 56px;
  background-image: url('../../../assets/img/result/dna_top_left.png'), url('../../../assets/img/result/dna_bottom_right.png');
  background-size: 290px, 300px;
  background-position: top left -90px, bottom right -90px;
  background-repeat: no-repeat;
  @media screen and (max-width: 768px) {
    padding: 48px 5% 56px;
  }

  .result-row-mobile {
    margin: 20px 0px 0px 0px;

    .result-col-mobile {
      width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      .paper {
        width: 90%;
        }
      .patent {
        width: 100%;
      }
    }


  }

}  
</style>