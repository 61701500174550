<template>
  <div class="contact-container-mobile">
    <el-row class="contact-row-mobile" >
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" class="logo-col-mobile">
        <div class="align-center-mobile">
          <img :src="logo" alt="" :class="{en: lang !== 'CN'}" />
          <span :class="{en: lang !== 'CN'}">{{ $t('common.centerName') }}</span>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8"  class="map-col-mobile">
        <Map class="map-border-mobile"></Map>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" class="contact-col-mobile">
        <div class="text-align-mobile">
          <span>{{ $t('navigator.contact') }}</span>
          <div class="align-center-mobile">
            <img :src="mail" alt="" />
            <span :class="{en: lang !== 'CN'}" :title="$t('contact.copyEmail')" @click="handleCopy">service.life-science@zhejianglab.com</span>
          </div>
          <div class="align-center-mobile">
            <img :src="location" alt="" />
            <span :title="$t('contact.toMap')" :class="{en: lang !== 'CN'}">
              <a :href="LabAddressUrl" target="_blank" style="text-decoration: none">
                {{ $t('contact.add') }}
              </a>
            </span>
          </div>
          <div class="align-center-mobile">
            <img :src="user" alt="" />
            <span :class="{en: lang !== 'CN'}">{{ $t('contact.learnMore') }}</span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
  
<script>
import Map from '../../components/Map.vue'
import { defineComponent, computed, inject } from 'vue'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import { useI18n } from 'vue-i18n';
const { toClipboard } = useClipboard()

export default defineComponent({
  name: 'Contact',
  components: { Map }
})
</script>

<script setup>
  const logo = require('@/assets/img/contact/logo.png')
  const mail = require('@/assets/img/contact/mail.svg')
  const location = require('@/assets/img/contact/location.svg')
  const user = require('@/assets/img/contact/user.svg')
  const LabAddressUrl = 'https://map.baidu.com/search/%E4%B9%8B%E6%B1%9F%E5%AE%9E%E9%AA%8C%E5%AE%A41%E6%9C%9F%E8%A5%BF%E5%8C%BA/@13347483.65857136,3516258.7253992,17.96z?querytype=s&da_src=shareurl&wd=%E4%B9%8B%E6%B1%9F%E5%AE%9E%E9%AA%8C%E5%AE%A41%E6%9C%9F%E8%A5%BF%E5%8C%BA&c=131&src=0&wd2=%E6%9D%AD%E5%B7%9E%E5%B8%82%E4%BD%99%E6%9D%AD%E5%8C%BA&pn=0&sug=1&l=13&b=(13309374,3498950;13391294,3539622)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=69dcaf6b2dd2197a260bd0bf&device_ratio=1'
  const i18n = useI18n();

  const lang = inject('globalLang')

  const handleCopy = async () => {
    try {
      await toClipboard("service.life-science@zhejianglab.com")
      ElMessage.success(i18n.t("common.copySuccess"))
    } catch (e) {
      ElMessage.error(i18n.t("common.copyFail"))
    }
  }

</script>

<style scoped lang="less">
.contact-container-mobile {
  padding: 48px 5% 56px;
  background-image: url('../../../assets/img/contact/bg_logo.png'),url('../../../assets/img/contact/bg.png');
  background-size: contain, cover;
  background-position: center, center;
  background-repeat: no-repeat;
  @media screen and (max-width: 480px) {
    padding: 52px 57px;
  }
  @media screen and (max-width: 414px) {
    padding: 52px 47px;
  }
  @media screen and (max-width: 360px) {
    padding: 52px 20px;
  }

  .align-center-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact-row-mobile {
    display: flex;
    .logo-col-mobile {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 40px;
      @media screen and (max-width: 480px) {
        margin-bottom: 20px;
      }

      span {
        font-size: 18px;
        font-weight: 1000;
        line-height: 24px;
        color: #ffffff;
        &.en {
          font-size: 16px;
        }
        @media screen and (max-width: 520px) {
          font-size: 16px;
          line-height: 20px;
          &.en {
            font-size: 14px;
          }
        }
      }

      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        @media screen and (max-width: 520px) {
          width: 20px;
          height: 20px;
        }
      }
    }

    .map-col-mobile {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 40px;
        @media screen and (max-width: 480px) {
          margin-bottom: 20px;
        }
        .map-border-mobile {
          border: 8px solid white;
        }
    }

    .contact-col-mobile {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .text-align-mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        height: 159px;
        @media screen and (max-width: 520px) {
          height: 120px;
        }
        span {
          opacity: 1;
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: normal;
          line-height: 16px;
          letter-spacing: 0px;
          font-variation-settings: "opsz" auto;
          color: rgba(255, 255, 255, 0.7);
          z-index: 1;
          &.en {
            font-size: 12px;
          }
          @media screen and (max-width: 520px) {
            font-size: 12px;
            &.en {
              font-size: 7px;
            }
          }
          a {
            color: rgba(255, 255, 255, 0.7);
          }

          &:first-child {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 20px;
            color: #FFFFFF;
            @media screen and (max-width: 520px) {
              font-size: 14px;
            }
            @media screen and (max-width: 480px) {
              margin-bottom: 10px;
            }
          }
        }

        img {
          width: 15px;
          height: 15px;
          margin-right: 8px;
          @media screen and (max-width: 520px) {
            width: 10px;
            height: 10px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>