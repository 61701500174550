<template>
  <div class="iscrc-portal-web-container">
    <!-- <el-row class="iscrc-portal-header">
      <el-col :sm="22" :xs="20" class="header-col">
        <img src="../../assets/img/logo.png" class="logo" alt="" />
        <span>之江实验室生命科学计算研究中心</span>
      </el-col>
      <el-col :sm="2" :xs="4" class="header-col right-col">
        <el-link :underline="false" class="col-btn left-btn">CN</el-link>
        <el-link :underline="false" class="col-btn">EN</el-link>
      </el-col>
    </el-row> -->

    <Carousel />

    <div class="top-btn-wrap">
      <ul class="top-btn" :class="{ dark: !scheme }">
        <li
          v-for="(item, index) in btnContent"
          :key="index"
          class="btn-content"
          :class="{ 'btn-active': index === activeIndex, en: lang !== 'CN' }"
          @click="selectBtn(index)"
        >
          {{ item }}
        </li>
      </ul>
    </div>

    <ResearchDirection id="modelRes" />
    <Dataset />
    <ComputingTools />
    <LargeModel />
    <ScientificExploration id="modelSci" />
    <Result id="modelResult" />
    <CooperativePartner />
    <Contact />
    <Copyright />
  </div>
</template>

<script setup>
import { ref, onUnmounted, onMounted, inject, computed } from 'vue'
import Carousel from './components/Carousel.vue'
import ComputingTools from './components/ComputingTools.vue'
import ResearchDirection from './components/ResearchDirection.vue'
import ScientificExploration from './components/ScientificExploration.vue'
import CooperativePartner from './components/CooperativePartner.vue'
import LargeModel from './components/LargeModel.vue'
import Dataset from './components/Dataset.vue'
import Result from './components/Result.vue'
import Contact from './components/Contact.vue'
import Copyright from './components/Copyright.vue'
import useDeviceStore from '@/store/device.js'
import { useI18n } from 'vue-i18n'
const i18n = useI18n()

const btnContent = computed(() => {
  return [
    i18n.t('navigator.researchDirection'),
    i18n.t('navigator.coreBenefits'),
    i18n.t('navigator.aboutUs'),
  ]
})

const activeIndex = ref(0)
const scheme = inject('globalScheme')

// 点击进行锚点定位
const selectBtn = (index) => {
  activeIndex.value = index
  const refList = ['modelRes', 'modelSci', 'modelResult']
  const element = document.getElementById(refList[index])
  element.scrollIntoView({ behavior: 'smooth' })
}

// 滚动监听，点亮所选中文字
const onScroll = () => {
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  const modelResOffsetTop = document.getElementById('modelRes').offsetTop
  const modelSciOffsetTop = document.getElementById('modelSci').offsetTop
  const modelResultOffsetTop = document.getElementById('modelResult').offsetTop
  if (scrollTop <= modelSciOffsetTop - 50) {
    activeIndex.value = 0
  } else if (
    scrollTop > modelSciOffsetTop - 50 &&
    scrollTop <= modelResultOffsetTop - 50
  ) {
    activeIndex.value = 1
  } else {
    activeIndex.value = 2
  }
}

onMounted(() => {
  const device = useDeviceStore()
  device.setDeviceScheme(true)
  window.addEventListener('scroll', onScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>

<style scoped lang="less">
.iscrc-portal-web-container {
  width: 100%;
  .top-btn-wrap {
    position: sticky;
    top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -50px auto -30px;
    z-index: 100;
    border-radius: 80;
    background: transparent;
    .top-btn {
      // box-shadow: 0px 9px 28px 0px rgba(0, 0, 0, 0.1);
      max-width: 450px;
      display: flex;
      list-style-type: none;
      border-radius: 100px;
      background-color: #fff;
      padding: 0 10px;
      .btn-content {
        font-size: 16px;
        text-align: center;
        color: #646566;
        margin: 14px 16px;
        cursor: pointer;
        font-family: PingFang SC;
        &.en {
          font-size: 16px;
          @media screen and (max-width: 415px) {
            font-size: 12px;
          }
        }
        &.btn-active {
          color: #2a6cf6;
          font-weight: 600;
        }
      }
      &.dark {
        background-color: #282e3a;
        color: rgba(255, 255, 255, 0.7);
        .btn-active {
          color: #fff;
        }
      }
    }
  }
}
</style>
