<template>
  <div class="computing-tools-container" :class="{ dark: !scheme }">
    <TitlePanel
      :title="$t('navigator.computingTools')"
      titleEn="Diverse Computing Tools"
    />
    <div class="tools-wrap">
      <div
        v-for="(item, index) in computingTools"
        :key="index"
        class="tools-row"
      >
        <div class="tools-icon">
          <img :src="item.icon" alt="" />
        </div>
        <div class="tools-col-wrap">
          <div
            v-for="(tool, index1) in item.tools"
            :key="index1"
            class="tools-col"
            :class="{ dark: !scheme }"
            @click="toLink(tool.link)"
          >
            <span :title="tool.name">{{ tool.name }} </span>
            <div
              v-if="tool.link"
              class="link-icon"
              :class="{ dark: !scheme }"
            ></div>
            <!-- <el-icon><Right /></el-icon> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import TitlePanel from '../../components/TitlePanel.vue'
import { onMounted, inject, computed } from 'vue'
import { Right } from '@element-plus/icons-vue'
import gene from '@/assets/img/computingtools/gene.png'
import protein from '@/assets/img/computingtools/protein.png'
import protogene from '@/assets/img/computingtools/protogene.png'
import sgrna from '@/assets/img/computingtools/sgrna.png'
import { useI18n } from 'vue-i18n'
const i18n = useI18n()

const computingTools = computed(() => {
  return [
    {
      icon: protein,
      tools: [
        { name: i18n.t('computingTools.protein.name1') },
        { name: i18n.t('computingTools.protein.name2') },
        { name: i18n.t('computingTools.protein.name3') },
        { name: i18n.t('computingTools.protein.name4') },
      ],
    },
    {
      icon: gene,
      tools: [
        { name: i18n.t('computingTools.gene.name1') },
        { name: i18n.t('computingTools.gene.name2') },
      ],
    },
    {
      icon: protogene,
      tools: [
        { name: i18n.t('computingTools.protogene.name1') },
        { name: i18n.t('computingTools.protogene.name2') },
      ],
    },
    {
      icon: sgrna,
      tools: [
        {
          name: i18n.t('computingTools.sgrna.name1'),
          link: 'https://crispr.zhejianglab.com',
        },
      ],
    },
  ]
})
const scheme = inject('globalScheme')

const toLink = (link) => {
  if (link) window.open(link)
}
</script>

<style scoped lang="less">
.computing-tools-container {
  padding: 48px 16px 58px;
  background-image: url('../../../assets/img/computingtools/bg.png'); // 浅色模式
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: relative;
  &.dark {
    background-image: linear-gradient(180deg, #17171a 50%, #17171a93 100%),
      url('../../../assets/img/computingtools/bg.png');
  }
  .tools-row {
    display: flex;
    // align-items: center;
    margin-top: 40px;
    .tools-icon {
      height: 54px;
      width: 54px;
      margin: 5px 6px 0 0;
      img {
        width: 100%;
      }
    }
    .tools-col-wrap {
      display: flex;
      width: calc(100% - 60px);
      flex-wrap: wrap;
    }
    .tools-col {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(50% - 32px);
      height: 24px;
      // box-shadow: 4px 4px 10px 0px rgba(42, 108, 246, 0.35);
      border-radius: 8px;
      padding: 13px 10px;
      margin: 5px 8px 5px 0;
      color: #283464;
      cursor: pointer;
      text-align: center;
      background: linear-gradient(
        109deg,
        rgba(255, 255, 255, 0.7) 8%,
        rgba(249, 251, 255, 0.5) 97%
      );
      &.dark {
        background: #282e3a;
        font-variation-settings: 'opsz' auto;
        color: #ffffff;
      }
      span {
        font-size: 14px;
        line-height: 22px;
        // max-width: 66%;
        // text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .link-icon {
        height: 16px;
        width: 16px;
        background-image: url('../../../assets/img/computingtools/link.png');
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 5px;
        &.dark {
          background-image: url('../../../assets/img/computingtools/linkwhite.svg');
        }
      }
      &:hover {
        font-weight: bold;
        background: linear-gradient(111deg, #598fff 12%, #1d62f1 95%);
        box-shadow: 4px 4px 10px 0px rgba(42, 108, 246, 0.35);
        color: #fff;
      }
    }
  }
}
</style>
