<template>
  <div ref="chart" style="width: 410px; height: 320px"></div>
</template>

<script setup>
import * as echarts from 'echarts/core'
import {
  DatasetComponent,
  TooltipComponent,
  VisualMapComponent,
} from 'echarts/components'
import { CustomChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
echarts.use([
  DatasetComponent,
  TooltipComponent,
  VisualMapComponent,
  CustomChart,
  CanvasRenderer,
])
import * as d3 from 'd3'
import { ref, onMounted } from 'vue'

const chart = ref(null)

let option = {}
let seriesData = [
  {
    id: 'data',
    parent: '',
    value: 0,
    r: 0,
    x: 0,
    y: 0,
  },
  {
    id: 'Md data',
    parent: 'data',
    value: 30 * 1000 * 1000 * 1000 * 1000,
    r: 60,
    x: 160,
    y: 140,
  },
  {
    id: 'Compounds',
    parent: 'data',
    value: 25 * 1000 * 1000,
    r: 50,
    x: 260,
    y: 260,
  },
  {
    id: 'Post-translations',
    parent: 'data',
    value: 1.1 * 1000 * 1000,
    r: 40,
    x: 40,
    y: 140,
  },
  {
    id: 'Affinities',
    parent: 'data',
    value: 0.9 * 1000 * 1000,
    r: 35,
    x: 140,
    y: 280,
  },
  {
    id: 'Proteins',
    parent: 'data',
    value: 0.46 * 1000 * 1000,
    r: 30,
    x: 270,
    y: 70,
  },
  {
    id: 'Rnas',
    parent: 'data',
    value: 0.16 * 1000 * 1000,
    r: 30,
    x: 80,
    y: 220,
  },
  {
    id: 'Dnas',
    parent: 'data',
    value: 45 * 1000,
    r: 25,
    x: 40,
    y: 280,
  },
  {
    id: 'Drugs',
    parent: 'data',
    value: 15 * 1000,
    r: 22,
    x: 260,
    y: 150,
  },
  {
    id: 'Pulications',
    parent: 'data',
    value: 85 * 1000,
    r: 18,
    x: 300,
    y: 180,
  },
  {
    id: 'Tissues',
    parent: 'data',
    value: 782,
    r: 16,
    x: 100,
    y: 50,
  },
]

const format = (value) => {
  if (value > 999) {
    const newValue = d3.format('0.2s')(value)
    if (newValue.indexOf('m') > -1) {
      return parseInt(newValue.replace('m', '')) / 1000
    }
    return newValue
  } else {
    return value
  }
}

let displayRoot = stratify1()

function stratify1() {
  return d3
    .stratify()
    .id((d) => d.id)
    .parentId((d) => d.parent)(seriesData)
  // .sum(function (d) {
  //   return d.value || 0
  // })
  // .sort(function (a, b) {
  //   return b.value - a.value
  // })
}

function overallLayout(params, api) {
  let context = params.context
  d3
    .pack()
    .size([api.getWidth() - 2, api.getHeight() - 2])
    .padding(2)(displayRoot)
  context.nodes = {}

  displayRoot.descendants().forEach(function (node) {
    context.nodes[node.id] = node
  })
}

function renderItem(params, api) {
  let context = params.context
  let idx = params.dataIndex

  if (!context.layout) {
    context.layout = true
    overallLayout(params, api)
  }

  let nodePath = api.value('id')
  let nodeName =
    format(api.value('value')) +
    '\n' +
    nodePath.split(/(?=[A-Z][^A-Z])/g).join('\n')
  let node = context.nodes[nodePath]
  node.r = api.value('r')
  node.x = api.value('x') + 20
  node.y = api.value('y')

  if (!node) {
    // Reder nothing.
    return
  }

  let opacity = 1 - idx / (seriesData.length + 2)

  let z2 = api.value('depth') * 2
  return {
    type: 'circle',
    shape: {
      cx: node.x,
      cy: node.y,
      r: node.r,
    },
    // transition: ['shape'],
    z2: z2,
    textContent: {
      type: 'text',
      style: {
        text: nodeName,
        fill: '#fff',
        fontFamily: 'Arial',
        width: node.r * 2,
        overflow: 'truncate',
        fontSize: node.r / 3,
      },
      emphasis: {
        style: {
          overflow: 'truncate',
          fontSize: node.r / 3,
        },
      },
    },
    textConfig: {
      position: 'inside',
    },
    style: {
      fill: '#007fff',
      opacity: opacity,
      scale: 1,
    },
    emphasis: {
      shape: {
        cx: node.x,
        cy: node.y,
        r: node.r * 1.15,
      },
      style: {
        opacity: 1,

        // fontFamily: 'Arial',
        // fontSize: 12,
        // shadowBlur: 20,
        // shadowOffsetX: 3,
        // shadowOffsetY: 5
      },
    },
  }
}

option = {
  dataset: {
    source: seriesData,
  },
  tooltip: {},
  hoverLayerThreshold: Infinity,
  series: [
    {
      type: 'custom',
      // colorBy: 'data',
      renderItem: renderItem,
      progressive: 0,
      coordinateSystem: 'none',
      encode: {
        tooltip: 'value',
        itemName: 'id',
      },
    },
  ],
}

const initEcharts = () => {
  const myChart = echarts.init(chart.value)
  myChart.setOption(option)
}

onMounted(() => {
  initEcharts()
})
</script>
