<template>
  <div class="iscrc-portal-web-container">
    <component :is="device.deviceInfo.type === 'pc' ? pc : mobile"></component>
  </div>
</template>

<script setup>
import { ref, onUnmounted, onMounted, computed, provide } from 'vue'
import pc from './pc/index.vue'
import mobile from './mobile/index.vue'
import useDeviceStore from '@/store/device.js'

const device = useDeviceStore()
const globalScheme = computed(() => device?.deviceInfo?.scheme)
provide('globalScheme', globalScheme)

const globalLang = computed(() => device?.deviceInfo?.lang)
provide('globalLang', globalLang)

onMounted(() => {})
</script>

<style scoped lang="less">
.iscrc-portal-web-container {
  width: 100%;
}
</style>
