<template>
  <div class="title-panel-wrap">
    <div class="title" :class="{ 'title-dark': !scheme }" :style="{ fontSize }">
      <div>{{ title }}</div>
      <div>{{ subTitle }}</div>
      <span
        class="title-en"
        :class="{ 'title-en-dark': !scheme }"
        :style="{ fontSize }"
        >{{ titleEn }}<br>{{ subTitleEn }}</span
      >
    </div>
    <slot></slot>
  </div>
</template>

<script setup>
import useDeviceStore from '@/store/device.js'
import { inject } from 'vue'

defineProps({
  title: String,
  subTitle: String,
  titleEn: String,
  subTitleEn: String,
})
const device = useDeviceStore()
const fontSize = device.deviceInfo.type === 'pc' ? '32px' : '24px'
const scheme = inject('globalScheme')
</script>

<style scoped lang="less">
@import '~@/assets/styles/common.less';
.title-panel-wrap {
  width: 100%;
  // margin-top: 86px;
  @media screen and (max-width: @maxWidth) {
    font-size: 24px;
    font-weight: 1000;
    line-height: normal;
    text-align: center;
  }
  .title {
    font-family: Alibaba-PuHuiTi-Bold;
    font-size: 32px;
    font-weight: 1000;
    line-height: 40px;
    color: #283464;
    position: relative;
    &.title-dark {
      color: #ffffff;
    }
    .title-en {
      position: absolute;
      bottom: -10px;
      left: 0;
      opacity: 0.1;
      font-size: 32px;
      font-weight: 800;
      line-height: 40px;
      background: linear-gradient(
        180deg,
        rgba(19, 25, 45, 0.2) 0%,
        #283464 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      width: 100%;
      font-style: italic;
      &.title-en-dark {
        font-variation-settings: 'opsz' auto;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.2) 0%,
          #ffffff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      @media screen and (max-width: @maxWidth) {
        text-align: center;
      }
    }
  }
}
</style>
