export default {
  common: {
    centerName: '之江实验室生命科学计算研究中心',
    tryButton: '立即体验',
    copySuccess: '复制成功！',
    copyFail: '复制失败!'
  },
  navigator: {
    researchDirection: '研究方向',
    coreBenefits: '核心优势',
    aboutUs: '关于我们',
    massiveDataset: '海量数据集',
    computingTools: '多样性工具',
    largeModel: '多模态AI大模型',
    scientificExploration1: '科学探索加速器',
    scientificExploration2: '：',
    scientificExploration3: '可视化服务云平台',
    result: '论文和专利',
    partner: '合作伙伴',
    contact: '联系我们',
    copyRight: '版权'
  },
  carousel: {
    carouselContent1: {
      title: '生命科学计算开放平台',
      descPc: '提供一站式的云智一体的产品化方案，运用IT（计算科学）和BT（生命科学）融合的方法，依托高质量湿实验数据集和算法的力量，以完善的数字化平台和研发体系，持续开发更为高效、快速、精准的计算工具，助力产业升级。',
      descMobile: '运用 IT（计算科学）和 BT（生命科学）融合的方法提供一站式的云智一体的产品化方案',
      button: '立即体验'
    },
    carouselContent2: {
      title: '最新成果：“三态门”基因电路让细胞开启智能运算',
      descPc: '之江实验室生命科学计算研究中心王慧，作为共同通讯作者与来自浙江大学、国防科技大学和西湖大学的联合研究团队在Cell发表了题为Multi-layered computational gene networks by engineered tristate logics的研究成果。',
      descMobile: '之江实验室生命科学计算研究中心王慧作为共同通讯作者在Cell发表研究成果',
      button: '查看详情'
    }
  },
  researchDirection: {
    direction1: '疾病诊疗',
    direction2: '人体仿真模拟',
    direction3: '基因治疗',
    direction4: '功能蛋白研发',
    direction5: '核酸检测',
    direction6: '疾病靶点调控',
    researchBaseName1: {
      name: '大模型',
      desc: '设计高泛化能力的预训练模型，提升多模态生命科学数据表征的准确性，通过智能体技术自主完成科学发现'
    },
    researchBaseName2: {
      name: '数据',
      desc: '实现多来源、多模态生命科学数据的智能整合标准化、高效流通与安全可控'
    },
    researchBaseName3: {
      name: '计算工具集',
      desc: '开发创新的应用计算方法筛选、生成并优化生物大分子构建人体数字孪生系统'
    },
    centerDogma: '中心法则的物质基础和调控',
    gotIt: '我知道了'
  },
  massiveDataset: {
    sequence: {
      title: '序列数据',
      desc1: '对DNA、RNA和蛋白质序列进行分析和研究，进而研究序列的生物学功能',
      unit: '亿',
      desc2: '蛋白序列',
      desc3: '数据量级'
    },
    omics: {
      title: '组学数据',
      desc1: '训练数据涵盖基因组、转录组、蛋白质组、代谢组，表观遗传学数据，突变数据等多组学数据；',
      desc2: '涵盖了近百个物种的核酸和蛋白质序列。'
    },
    interaction: {
      title: '互作数据',
      desc1: '涵盖已知和预测的蛋白质、核酸、小分子之间相互作用数据。',
      desc2: '这些相互作用包括直接（物理）和间接（功能）关联；它们来自计算预测、生物体之间的知识转移以及从其他公开数据库中汇总的相互作用。',
      desc3: '涉及主要模式生物物种的蛋白质和基因相互作用、化学相互作用以及翻译后修饰。'
    },
    literature: {
      title: '文献数据',
      desc1: '追踪并检测生命科学领域前沿动态',
      desc2: '构建文献信息抽取全链路',
      desc3: '提高蛋白酶实验活性信息',
      desc4: '高效辅助科学研究'
    }
  },
  computingTools: {
    protein: {
      name1: '蛋白质结构预测',
      name2: '蛋白序列优化',
      name3: '结合位点预测',
      name4: '高通量筛选'
    },
    gene: {
      name1: '核酸序列进化树',
      name2: 'RNA结构预测'
    },
    protogene: {
      name1: '蛋白相互作用预测',
      name2: 'ncRNA-蛋白相互作用预测'
    },
    sgrna: {
      name1: '智能sgRNA设计'
    }
  },
  largeModel: {
    sequence: {
      title: '序列预训练大模型',
      desc1: '蛋白语言大模型',
      desc2: '生物大分子序列'
    },
    omics: {
      title: '组学预训练大模型',
      desc: '基因组学、转录组学、蛋白质组学、代谢组学和空间组学'
    },
    interact: {
      title: '互作预训练大模型',
      desc: '蛋白质与小分子间的相互作用，指导蛋白质工程和药物设计'
    },
  },
  scientificExploration: {
    one:{
      title: '更懂科学家诉求的平台',
      desc: '立足科学家和生信分析人员，面向学校、企业、研究机构提供定制化的开发服务。',
    },
    two: {
      title: '兼具数据共享与隐私保护',
      desc: '精心设计的权限管理系统，该数据平台允许在不同组织和团队之间无需物理传输数据即可实现安全的数据共享，同时保障隐私数据。',
    },
    three: {
      title: 'AI+大数据驱动，精准预测',
      desc: '平台由数十亿蛋白序列+海量组学数据，借助自主研发的基座大模型，预测更精准有效。',
    },
    four: {
      title: '可视高效，自动化处理计算过程',
      desc: '在线可用的云服务平台，可根据需要自定义创建任务并在后台运行，轻松获取计算结果。',
    }
  },
  result: {},
  partner: {
    desc: '涵盖高水平大学和创新型企业'
  },
  contact: {
    copyEmail: '点击复制邮箱',
    add: '浙江省杭州市余杭区之江实验室一期西区11号楼',
    toMap: '跳转到百度地图',
    learnMore: '关注“之江实验室”公众号，了解更多资讯'
  },
  copyRight: {}
}