<template>
    <div class="copyright-container-mobile">
        <p>© 2024 ALL Rights Reserved | 版号</p>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Copyright'
})
</script>

<script setup>
    const logo = require('@/assets/img/contact/logo.png')
</script>

<style scoped lang="less">
    .copyright-container-mobile {
        height: 48px;
        background-image: url('../../../assets/img/copyright/bg.png');
        background-size: cover;
        background-repeat: no-repeat;

        display: flex;
        justify-content: center;
        align-content: center;

        p {
            font-family: PingFang SC;
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0px;

            font-variation-settings: "opsz" auto;
            color: rgba(255, 255, 255, 0.7);
        }

    }
</style>