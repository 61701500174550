<template>
<div class="contact-container-pc">
  <el-row class="contact-row-pc" >
    <el-col :span="8" class="logo-col-pc">
      <div class="align-center-pc">
        <img :src="logo" alt="" :class="{en: lang !== 'CN'}"/>
        <span :class="{en: lang !== 'CN'}">{{ $t('common.centerName') }}</span>
      </div>
    </el-col>
    <el-col :span="8"  class="map-col-pc">
      <Map class="map-border-pc"></Map>
    </el-col>
    <el-col :span="8" class="contact-col-pc">
      <div class="text-align-pc">
        <span>{{ $t('navigator.contact') }}</span>
        <div class="align-center-pc">
          <img :src="mail" alt="" />
          <span :class="{en: lang !== 'CN'}" :title="$t('contact.copyEmail')" @click="handleCopy">service.life-science@zhejianglab.com</span>
        </div>
        <div class="align-center-pc">
          <img :src="location" alt="" />
          <span :title="$t('contact.toMap')" :class="{en: lang !== 'CN'}">
            <a :href="LabAddressUrl" target="_blank" style="text-decoration: none">
              {{ $t('contact.add') }}
            </a>
          </span>
        </div>
        <div class="align-center-pc">
          <img :src="user" alt="" />
          <span :class="{en: lang !== 'CN'}">{{ $t('contact.learnMore') }}</span>
        </div>
      </div>
    </el-col>
  </el-row>
</div>
</template>
  
<script>
import Map from '../../components/Map.vue'
import { defineComponent, inject } from 'vue'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
const { toClipboard } = useClipboard()
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Contact',
  components: { Map }
})
</script>

<script setup>
  const logo = require('@/assets/img/contact/logo.png')
  const mail = require('@/assets/img/contact/mail.svg')
  const location = require('@/assets/img/contact/location.svg')
  const user = require('@/assets/img/contact/user.svg')
  const LabAddressUrl = 'https://map.baidu.com/search/%E4%B9%8B%E6%B1%9F%E5%AE%9E%E9%AA%8C%E5%AE%A41%E6%9C%9F%E8%A5%BF%E5%8C%BA/@13347483.65857136,3516258.7253992,17.96z?querytype=s&da_src=shareurl&wd=%E4%B9%8B%E6%B1%9F%E5%AE%9E%E9%AA%8C%E5%AE%A41%E6%9C%9F%E8%A5%BF%E5%8C%BA&c=131&src=0&wd2=%E6%9D%AD%E5%B7%9E%E5%B8%82%E4%BD%99%E6%9D%AD%E5%8C%BA&pn=0&sug=1&l=13&b=(13309374,3498950;13391294,3539622)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=69dcaf6b2dd2197a260bd0bf&device_ratio=1'
  const i18n = useI18n();

  const lang = inject('globalLang')

  const handleCopy = async () => {
    try {
      await toClipboard("service.life-science@zhejianglab.com")
      ElMessage.success(i18n.t("common.copySuccess"))
    } catch (e) {
      ElMessage.error(i18n.t("common.copyFail"))
    }
  }
</script>

<style scoped lang="less">
.contact-container-pc {
  width: 1440px;
  height: 148px;
  padding: 86px 0px;
  margin: 0 auto;
  position: relative;
  
  background-image: url('../../../assets/img/contact/bg_logo.png'),url('../../../assets/img/contact/bg.png');
  background-size: 500px, cover;
  background-position: -50px 0, center;
  background-repeat: no-repeat;

  .align-center-pc {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact-row-pc {
    display: flex;

    .logo-col-pc {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      span {
        font-size: 24px;
        font-weight: 1000;
        line-height: 24px;
        color: #ffffff;
        &.en {
          font-size: 20px;
        }
      }

      img {
        width: 28px;
        height: 28px;
        margin-right: 8px;
        &.en {
          margin-left: 20px;
        }
      }
    }

    .map-col-pc {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .map-border-pc {
          border: 8px solid white;
        }
    }

    .contact-col-pc {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .text-align-pc {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        height: 159px;

        span {
          opacity: 1;
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: normal;
          line-height: 16px;
          letter-spacing: 0px;
          font-variation-settings: "opsz" auto;
          color: rgba(255, 255, 255, 0.7);
          z-index: 1;
          &.en {
            font-size: 12px;
          }

          a {
            color: rgba(255, 255, 255, 0.7);
          }

          &:first-child {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 20px;
            color: #FFFFFF;
          }
        }

        img {
          width: 15px;
          height: 15px;
          margin-right: 8px;
        }
      }
    }
  }
}

</style>