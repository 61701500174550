<template>
  <div :class="scheme? 'result-container-pc result-container-pc-light':'result-container-pc result-container-pc-dark'">
    <el-row>
      <TitlePanel 
        :title="$t('navigator.result')" 
        titleEn="Publications and Patents"
      > 
      </TitlePanel>
    </el-row>
    <el-row class="result-row-pc">
      <el-col :span="8" class="result-col-pc">
        <img class="paper" :src="paper" alt="paper" />
      </el-col>
      <el-col :span="16" class="result-col-pc">
        <img class="patent" :src="patent" alt="patent" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TitlePanel from '../../components/TitlePanel.vue'
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'Result',
  components: { TitlePanel },
})
</script>

<script setup>
const paper = require('@/assets/img/result/paper.png')
const patent = require('@/assets/img/result/patent.png')
const scheme = inject('globalScheme')
</script>

<style scoped lang="less">
.result-container-pc-light {
  background-color: #ffffff;
  background-image: url('../../../assets/img/result/pc/bg.png');
}
.result-container-pc-dark {
  background-color: #17171A;
  background-image: url('../../../assets/img/result/pc/bg_dark.png');
}
.result-container-pc {
  width: 1244px;
  height: 638px;
  padding: 86px 98px;
  margin:  0 auto;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .result-row-pc {
    margin: 60px 0px 0px 60px;

    .result-col-pc {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .paper {
      width: 390px;
    }
    .patent {
      width: 660px;
    }
  }
}
</style>
