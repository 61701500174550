<template>
  <div class="computing-tools-container" :class="{ dark: !scheme }">
    <TitlePanel
      :title="$t('navigator.computingTools')"
      titleEn="Diverse Computing Tools"
    />
    <div class="tools-wrap">
      <div
        v-for="(item, index) in computingTools"
        :key="index"
        class="tools-row"
      >
        <div class="tools-icon">
          <img :src="item.icon" alt="" />
        </div>
        <div
          v-for="(tool, idx) in item.tools"
          :key="idx"
          class="tools-col"
          :class="{
            dark: !scheme,
            link: tool.link,
          }"
          @click="toLink(tool.link)"
        >
          <span :class="{ en: lang !== 'CN' }" :title="tool.name">{{ tool.name }} </span>
          <div v-if="tool.link" class="icon" :class="{ dark: !scheme }"></div>
          <!-- <el-icon><Right /></el-icon> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import TitlePanel from '../../components/TitlePanel.vue'
import { computed, onMounted, ref, inject } from 'vue'
import { Right } from '@element-plus/icons-vue'
import gene from '@/assets/img/computingtools/gene.png'
import protein from '@/assets/img/computingtools/protein.png'
import protogene from '@/assets/img/computingtools/protogene.png'
import sgrna from '@/assets/img/computingtools/sgrna.png'
import { useI18n } from 'vue-i18n'

const lang = inject('globalLang')

const i18n = useI18n()

const computingTools = computed(() => {
  return [
    {
      icon: protein,
      tools: [
        { name: i18n.t('computingTools.protein.name1') },
        { name: i18n.t('computingTools.protein.name2') },
        { name: i18n.t('computingTools.protein.name3') },
        { name: i18n.t('computingTools.protein.name4') },
      ],
    },
    {
      icon: gene,
      tools: [
        { name: i18n.t('computingTools.gene.name1') },
        { name: i18n.t('computingTools.gene.name2') },
      ],
    },
    {
      icon: protogene,
      tools: [
        { name: i18n.t('computingTools.protogene.name1') },
        { name: i18n.t('computingTools.protogene.name2') },
      ],
    },
    {
      icon: sgrna,
      tools: [
        {
          name: i18n.t('computingTools.sgrna.name1'),
          link: 'https://crispr.zhejianglab.com',
        },
      ],
    },
  ]
})

const scheme = inject('globalScheme')

const toLink = (link) => {
  if (link) window.open(link)
}
</script>

<style scoped lang="less">
.computing-tools-container {
  background-image: url('../../../assets/img/computingtools/bg.png'); // 浅色模式
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  width: 1244px;
  padding: 86px 98px 132px;
  margin: 0 auto;
  &.dark {
    background-image: linear-gradient(180deg, #17171a 50%, #17171a93 100%),
      url('../../../assets/img/computingtools/bg.png');
  }
  .tools-wrap {
    margin-top: 114px;
  }
  .tools-row {
    display: flex;
    align-items: center;
    margin-top: 36px;
    .tools-icon {
      height: 90px;
      width: 90px;
      margin: 0 36px 0 20px;
      img {
        width: 100%;
      }
    }
    .tools-col {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(25% - 58px);
      border-radius: 16px;
      padding: 16px 0;
      margin-right: 20px;
      backdrop-filter: blur(10px);
      color: #283464;
      // font-family: 'Alibaba-PuHuiTi-Bold';
      &.dark {
        background: #282e3a;
        font-variation-settings: 'opsz' auto;
        color: #ffffff;
      }
      &.link {
        cursor: pointer;
      }
      span {
        font-size: 20px;
        line-height: 32px;
        max-width: 75%;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &.en{
          font-size: 16px;
          line-height: 32px;
          max-width: 96%;
          text-align: left;
          font-weight: bold;
        }
      }
      .icon {
        height: 16px;
        width: 16px;
        background-image: url('../../../assets/img/computingtools/link.png');
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 10px;
        &.dark {
          background-image: url('../../../assets/img/computingtools/linkwhite.svg');
        }
      }
      background: linear-gradient(
        105deg,
        rgba(255, 255, 255, 0.7) 8%,
        rgba(249, 251, 255, 0.5) 97%
      );
      transition: background-color 0.4s; // CSS3transition不支持 background-image，支持background-color，background-position
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background: linear-gradient(111deg, #598fff 12%, #1d62f1 95%);
        opacity: 0;
        transition: opacity 0.4s;
        border-radius: 16px;
      }
      &:hover {
        color: #fff;
        font-weight: bold;
        .icon {
          background-image: url('../../../assets/img/computingtools/linkwhite.svg');
        }
        box-shadow: 4px 4px 10px 0px rgba(42, 108, 246, 0.35);
      }
      &:hover::before {
        opacity: 1;
      }
    }
  }
}
@keyframes mouse-in {
  0% {
    opacity: 0;
    background: transparent;
  }
  12% {
    background: linear-gradient(111deg, #598fff 12%, #1d62f1 95%);
    color: #fff;
    font-weight: bold;
    opacity: 1;
  }
}
@keyframes mouse-out {
  from {
    background: linear-gradient(111deg, #598fff 12%, #1d62f1 95%);
    color: #fff;
    font-weight: bold;
    opacity: 1;
  }
  to {
    opacity: 0;
    color: #283464;
    background: linear-gradient(
      105deg,
      rgba(255, 255, 255, 0.7) 8%,
      rgba(249, 251, 255, 0.5) 97%
    );
  }
}
</style>
