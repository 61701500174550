<template>
  <div
    class="iscrc-pc-container"
    id="pcContanier"
    :class="{ dark: !scheme }"
    @scroll="onScroll"
  >
    <Carousel />
    <div class="top-btn-wrap">
      <ul class="top-btn" :class="{ dark: !scheme, en: lang !== 'CN' }">
        <li
          v-for="(item, index) in btnContent"
          :key="index"
          class="btn-content"
          :class="{ 'btn-active': index === activeIndex }"
          @click="selectBtn(index)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <ResearchDirection id="modelRes" />
    <Dataset />
    <ComputingTools />
    <LargeModel />
    <ScientificExploration id="modelSci" />
    <Result id="modelResult" />
    <CooperativePartner />
    <Contact />
    <Copyright />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, inject, computed } from 'vue'
import ResearchDirection from './components/ResearchDirection.vue'
import Carousel from './components/Carousel.vue'
import ComputingTools from './components/ComputingTools.vue'
import ScientificExploration from './components/ScientificExploration.vue'
import CooperativePartner from './components/CooperativePartner.vue'
import LargeModel from './components/LargeModel.vue'
import Dataset from './components/Dataset.vue'
import Result from './components/Result.vue'
import Contact from './components/Contact.vue'
import Copyright from './components/Copyright.vue'
import useDeviceStore from '@/store/device.js'
import { useI18n } from 'vue-i18n'
import { setRem } from '@/utils/rem.js'
const i18n = useI18n()

const btnContent = computed(() => {
  return [
    i18n.t('navigator.researchDirection'),
    i18n.t('navigator.coreBenefits'),
    i18n.t('navigator.aboutUs'),
  ]
})

const activeIndex = ref(0)
const scheme = inject('globalScheme')
const lang = inject('globalLang')

// 点击进行锚点定位
const selectBtn = (index) => {
  activeIndex.value = index
  const refList = ['modelRes', 'modelSci', 'modelResult']
  const element = document.getElementById(refList[index])
  element.scrollIntoView({ behavior: 'smooth' })
}

// 滚动监听，点亮所选中文字
const onScroll = () => {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop
  // const offsetTopModelRes = document.getElementById('modelRes').offsetTop
  const offsetTopModelSci = document.getElementById('modelSci').offsetTop
  const offsetTopmodelResult = document.getElementById('modelResult').offsetTop

  if (scrollTop <= offsetTopModelSci - 50) {
    activeIndex.value = 0
  } else if (
    scrollTop > offsetTopModelSci - 50 &&
    scrollTop <= offsetTopmodelResult - 50
  ) {
    activeIndex.value = 1
  } else {
    activeIndex.value = 2
  }
}

onMounted(() => {
  setRem()
  window.addEventListener('scroll', onScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>

<style scoped lang="less">
.iscrc-pc-container {
  width: 100%;
  background-color: #f2f3f5;
  position: relative;
  &.dark {
    background-color: #1f2020;
  }

  .top-btn-wrap {
    // position: sticky;
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -12px auto 0;
    // margin: -80px auto -10px;
    z-index: 100;
    border-radius: 0 80px 0 0;
    background: transparent;
    .top-btn {
      width: 364px;
      display: flex;
      list-style-type: none;
      border-radius: 100px;
      background-color: transparent;
      // background-color: #ffffff;
      // box-shadow: 0px 9px 28px 0px rgba(0, 0, 0, 0.1);
      &.en {
        width: 500px;
      }
      .btn-content {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #646566;
        margin: 16px 20px;
        cursor: pointer;
        font-family: PingFang SC;
        &.btn-active {
          color: #2a6cf6;
          font-weight: 600;
        }
        &:hover {
          color: #2a6cf6;
        }
      }
      &.dark {
        // background-color: #282e3a;
        color: rgba(255, 255, 255, 0.7);
        .btn-active {
          color: #fff;
        }
        .btn-content:hover {
          color: #fff;
        }
      }
    }
  }
}
</style>
