import { defineStore } from "pinia";
import { reactive } from "vue";
import { MOBILE_WIDTH } from '@/utils/const.js'
import i18n from '@/assets/lang'

const useDeviceStore = defineStore('device', () => {
  const deviceInfo = reactive({
    type: 'pc',
    scheme: true,
    lang: localStorage.getItem('lang') ?? 'CN'
  })

  const setDeviceType = () => {
    const userAgentInfo = navigator.userAgent
    const Agents = [
      'Android',
      'iPhone',
      'SymbianOS',
      'Windows Phone',
      'iPad',
      'iPod'
    ]
    for (let i = 0; i < Agents.length; i++) {
      if (userAgentInfo.indexOf(Agents[i]) > 0) {
        deviceInfo.type = 'mobile'
        break
      }
    }
    let width =
      document.documentElement.clientWidth || document.body.clientWidth
    if (deviceInfo.type === 'pc' && width < MOBILE_WIDTH) {
      deviceInfo.type = 'mobile'
    }
    if (deviceInfo.type === 'mobile' && width >= MOBILE_WIDTH) {
      deviceInfo.type = 'pc'
    }
  }

  const setDeviceScheme = (scheme) => {
    deviceInfo.scheme = scheme
  }

  const setDeviceLang = (lang) => {
    deviceInfo.lang = lang
    localStorage.setItem("lang", lang)
    i18n.global.locale.value = lang
  }

  setDeviceType()
  return {
    deviceInfo,
    setDeviceType,
    setDeviceScheme,
    setDeviceLang
  }
})

export default useDeviceStore;
