<template>
  <div class="scientific-exploration-container" :class="{ dark: !scheme }" @mouseenter="animateRender">
    <TitlePanel
      :title="$t('navigator.scientificExploration1') + $t('navigator.scientificExploration2')"
      :subTitle="$t('navigator.scientificExploration3')"
      titleEn="Scientific Exploration Accelerator: "
      subTitleEn="Visualization Service Cloud Platform"
    />
    <div
      v-if="animateStep1"
      class="exploration-wrap animate__animated animate__fadeInDown"
    >
      <div class="exploration-img">
        <img :src="scheme ? bg : bgDark" alt="" />
        <a class="btn" href="https://cloud.aigene.org.cn/" target="_blank">
          {{ $t("common.tryButton") }}
        </a>
      </div>
    </div>
    <div
      v-if="animateStep2"
      class="exploration-content animate__animated animate__fadeInDown"
    >
      <div v-for="(item, index) in explorationContent" :key="index" class="exploration-desc">
        <div class="title" :class="{ dark: !scheme }">
          <div class="bar"></div>
          <span :title="item.title">{{ item.title }}</span>
        </div>
        <div class="desc" :class="{ dark: !scheme }">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import TitlePanel from '../../components/TitlePanel.vue'
import { onMounted, ref, onUnmounted, inject, computed } from 'vue'
import bg from '@/assets/img/scientificexploration/bg.png'
import bgDark from '@/assets/img/scientificexploration/bg_dark.png'
import { useI18n } from 'vue-i18n';
const i18n = useI18n();

const scheme = inject('globalScheme')
const explorationContent = computed(() => {
  return [
    {
      title: i18n.t("scientificExploration.one.title"),
      desc: i18n.t("scientificExploration.one.desc")
    },
    {
      title: i18n.t("scientificExploration.two.title"),
      desc: i18n.t("scientificExploration.two.desc")
    },
    {
      title: i18n.t("scientificExploration.three.title"),
      desc: i18n.t("scientificExploration.three.desc")
    },
    {
      title: i18n.t("scientificExploration.four.title"),
      desc: i18n.t("scientificExploration.four.desc")
    }
  ]
})
const animateStep1 = ref(false)
const animateStep2 = ref(false)

const animateRender = () => {
  function stepOne() {
    setTimeout(() => {
      animateStep1.value = true
      stepTwo()
    })
  }
  function stepTwo() {
    setTimeout(() => {
      animateStep2.value = true
    }, 500)
  }
  stepOne()
}

const onScroll = () => {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop
  if (scrollTop > 2700 && scrollTop < 4000) {
    animateRender()
    window.removeEventListener('scroll', onScroll)
  }
}

onMounted(() => {
  window.addEventListener('scroll', onScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>

<style scoped lang="less">
.scientific-exploration-container {
  padding: 48px 32px 56px;
  margin: 0 auto;
  position: relative;
  background: #eff4ff; // 浅色模式
  &.dark {
    background: #17171a; //深色模式
  }
  .exploration-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0 48px;
    .exploration-img {
      width: 55%;
      min-width: 240px;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
      .btn {
        background: linear-gradient(113deg, #598fff 11%, #1d62f1 96%);
        box-shadow: 4px 4px 10px 0px rgba(42, 108, 246, 0.35);
        border-radius: 100px;
        height: 42px;
        line-height: 42px;
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        width: 70%;
        cursor: pointer;
        text-decoration: unset;
      }
    }
  }
  .exploration-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .exploration-desc {
      width: 100%;
      .title {
        font-size: 16px;
        font-weight: 800;
        color: #283464;
        display: flex;
        align-items: center;
        font-family: Alibaba-PuHuiTi-Bold;
        .bar {
          background: linear-gradient(167deg, #598fff 0%, #1d62f1 113%);
          width: 6px;
          height: 12px;
          border-radius: 100px;
          margin-right: 8px;
        }
        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        &.dark {
          color: rgba(255, 255, 255, 1);
        }
      }
      .desc {
        color: #646566;
        font-size: 12px;
        font-weight: normal;
        margin: 8px 0 24px;
        &.dark {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
}
</style>
