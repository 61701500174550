<template>
  <div
    :class="
      scheme
        ? 'dataset-container-pc dataset-container-pc-light'
        : 'dataset-container-pc dataset-container-pc-dark'
    "
  >
    <div class="bottom-margin-60 align-horizontal-center-space-between">
      <TitlePanel 
        :title="$t('navigator.massiveDataset')" 
        titleEn="Massive Datasets"
      > 
      </TitlePanel>
      <div class="align-horizontal-center">
        <el-button
          :class="
            currectClick === 1
              ? scheme
                ? 'go-button-activate-pc'
                : 'go-button-activate-pc dark'
              : 'go-button-no-activate-pc'
          "
          style="border-radius: 100px; margin-right: 20px"
          @click="buttonClick(1)"
          @mouseenter="buttonHover = true"
          @mouseleave="buttonHover = false"
        >
        {{ $t('massiveDataset.sequence.title') }}
        </el-button>
        <el-button
          :class="
            currectClick === 2
              ? scheme
                ? 'go-button-activate-pc'
                : 'go-button-activate-pc dark'
              : 'go-button-no-activate-pc'
          "
          style="border-radius: 100px; margin-right: 20px"
          @click="buttonClick(2)"
          @mouseenter="buttonHover = true"
          @mouseleave="buttonHover = false"
        >
        {{ $t('massiveDataset.omics.title') }}
        </el-button>
        <el-button
          :class="
            currectClick === 3
              ? scheme
                ? 'go-button-activate-pc'
                : 'go-button-activate-pc dark'
              : 'go-button-no-activate-pc'
          "
          style="border-radius: 100px; margin-right: 20px"
          @click="buttonClick(3)"
          @mouseenter="buttonHover = true"
          @mouseleave="buttonHover = false"
        >
        {{ $t('massiveDataset.interaction.title') }}
        </el-button>
        <el-button
          :class="
            currectClick === 4
              ? scheme
                ? 'go-button-activate-pc'
                : 'go-button-activate-pc dark'
              : 'go-button-no-activate-pc'
          "
          style="border-radius: 100px; margin-right: 20px"
          @click="buttonClick(4)"
          @mouseenter="buttonHover = true"
          @mouseleave="buttonHover = false"
        >
        {{ $t('massiveDataset.literature.title') }}
        </el-button>
      </div>
    </div>

    <div
      v-if="currectClick === 1"
      class="layer-0 align-horizontal-center dataset-content-pc"
    >
      <div class="align-horizontal-center-space-around">
        <div class="align-horizontal-center text-content-pc" :class="{en: lang !== 'CN'}">
          <div class="align-vertical-center-space-between text-content-sequence-pc" :class="{en: lang !== 'CN'}">
            <p :class="{ dark: !scheme, en: lang !== 'CN' }" style="margin-bottom: 80px">
              {{ $t('massiveDataset.sequence.desc1') }}
            </p>
            <div class="align-horizontal-center-space-between">
              <div class="top-bottom-margin-20 align-vertical-center">
                <div>
                  <span v-if="lang === 'CN'" class="text-data-pc" :class="{ dark: !scheme }">6</span>
                  <span v-else class="text-data-pc" :class="{ dark: !scheme }">600</span>
                  <span class="text-unit-pc" :class="{ dark: !scheme }"
                    >{{ $t('massiveDataset.sequence.unit') }}</span
                  >
                </div>
                <p :class="{ dark: !scheme }"><b>{{ $t('massiveDataset.sequence.desc2') }}</b></p>
              </div>
              <div class="top-bottom-margin-20 align-vertical-center">
                <div>
                  <span class="text-data-pc" :class="{ dark: !scheme }"
                    >15</span
                  >
                  <span class="text-unit-pc" :class="{ dark: !scheme }"
                    >TB</span
                  >
                </div>
                <p :class="{ dark: !scheme }"><b>{{ $t('massiveDataset.sequence.desc3') }}</b></p>
              </div>
            </div>
          </div>
        </div>
        <div class="align-horizontal-end img-content-pc" :class="{en: lang !== 'CN'}">
          <img :src="sequence_data" alt="" />
        </div>
      </div>
    </div>

    <div
      v-if="currectClick === 2"
      class="layer-0 align-horizontal-center dataset-content-pc"
    >
      <div class="align-horizontal-center-space-around">
        <div class="align-horizontal-center text-content-pc" :class="{en: lang !== 'CN'}">
          <div class="align-vertical-center-space-between text-content-omics-pc" :class="{en: lang !== 'CN'}">
            <ul class="line-style-pc" :class="{ dark: !scheme, en: lang !== 'CN' }">
              <li>{{ $t('massiveDataset.omics.desc1') }}</li>
              <li>{{ $t('massiveDataset.omics.desc2') }}</li>
            </ul>
          </div>
        </div>
        <div class="align-horizontal-center img-content-pc" :class="{en: lang !== 'CN'}">
          <div class="img-content-omics-pc" :class="{ dark: !scheme }">
            <div class="align-vertical-center" style="padding: 40px">
              <img :src="omics_data_image" alt="" />
              <img :src="lang === 'CN' ? omics_data_legend : omics_data_legend_en " alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="currectClick === 3"
      class="layer-0 align-horizontal-center dataset-content-pc"
    >
      <div class="align-horizontal-center-space-around">
        <div class="align-horizontal-center text-content-pc" :class="{en: lang !== 'CN'}">
          <div class="align-vertical-center-space-between text-content-interaction-pc" :class="{en: lang !== 'CN'}">
            <ul class="line-style-pc" :class="{ dark: !scheme, en: lang !== 'CN' }">
              <li>{{ $t('massiveDataset.interaction.desc1') }}</li>
              <li>{{ $t('massiveDataset.interaction.desc2') }}</li>
              <li>{{ $t('massiveDataset.interaction.desc3') }}</li>
            </ul>
          </div>
        </div>
        <div class="align-horizontal-end img-content-pc" :class="{en: lang !== 'CN'}">
          <!-- <img :src="interaction_data" alt=""> -->
          <WordChartPC />
        </div>
      </div>
    </div>

    <div
      v-if="currectClick === 4"
      class="layer-0 align-horizontal-center dataset-content-pc"
    >
      <div class="align-horizontal-center-space-around">
        <div class="align-horizontal-center text-content-pc" :class="{en: lang !== 'CN'}">
          <div class="align-vertical-center-space-between text-content-literature-pc" :class="{en: lang !== 'CN'}">
            <ul class="line-style-pc" :class="{ dark: !scheme, en: lang !== 'CN' }">
              <li>{{ $t('massiveDataset.literature.desc1') }}</li>
              <li>{{ $t('massiveDataset.literature.desc2') }}</li>
              <li>{{ $t('massiveDataset.literature.desc3') }}</li>
              <li>{{ $t('massiveDataset.literature.desc4') }}</li>
            </ul>
          </div>
        </div>
        <div class="align-horizontal-center img-content-pc" :class="{en: lang !== 'CN'}">
          <img :src="literature_data" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitlePanel from '../../components/TitlePanel.vue'
import WordChartPC from '../../components/WordChart_pc.vue'
import {
  ref,
  defineComponent,
  watch,
  onMounted,
  onBeforeUnmount,
  inject,
} from 'vue'

export default defineComponent({
  name: 'Dataset',
  // components: { TitlePanel }
  components: { TitlePanel, WordChartPC },
})
</script>

<script setup>
const timerId = ref(null)
const currectClick = ref(1)
const buttonHover = ref(false)


const scheme = inject('globalScheme')
const lang = inject('globalLang')

const sequence_data = require('@/assets/img/dataset/sequence_data.png')
const omics_data_image = require('@/assets/img/dataset/omics_data_image.png')
const omics_data_legend = require('@/assets/img/dataset/omics_data_legend.png')
const omics_data_legend_en = require('@/assets/img/dataset/omics_data_legend_en.png')
const interaction_data = require('@/assets/img/dataset/interaction_data.png')
const literature_data = require('@/assets/img/dataset/literature_data.png')

const buttonClick = (value = 1) => {
  clearTimeout(timerId.value)
  currectClick.value = value
  startTimer()
}

function buttonCircle() {
  if (currectClick.value === 4) {
    currectClick.value = 1
  } else {
    currectClick.value++
  }
}

function startTimer() {
  clearTimeout(timerId.value)
  timerId.value = setTimeout(() => {
    startTimer(), buttonCircle()
  }, 5000)
}

watch(
  buttonHover,
  (newValue) => {
    if (newValue) {
      clearTimeout(timerId.value)
    } else {
      startTimer()
    }
  },
  {
    immediate: true,
  }
)

onMounted(() => {
  startTimer()
})

onBeforeUnmount(() => {
  if (timerId.value !== null) {
    clearTimeout(timerId.value)
    timerId.value = null
  }
})
</script>

<style scoped lang="less">
.dataset-container-pc-light {
  background-image: url('../../../assets/img/dataset/pc/bg.png'); // 浅色模式
}
.dataset-container-pc-dark {
  background-image: url('../../../assets/img/dataset/pc/bg_dark.png'); // 深色模式
}
.dataset-container-pc {
  width: 1270px;
  height: 638px;
  padding: 86px 72px 86px 98px;
  margin: 0 auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;

  .align-horizontal-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  .align-horizontal-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .align-horizontal-center-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .align-horizontal-center-space-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }

  .align-vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .align-vertical-center-space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 80%;
  }

  .bottom-margin-60 {
    margin: 0 0 60px 0;
  }

  .top-bottom-margin-20 {
    margin: 20px 0;
  }

  .dataset-content-pc {
    .text-content-pc {
      width: 475px;
      height: 468px;
      &.en {
        width: 624px;
      }
      .text-content-sequence-pc {
        width: 380px;
        &.en {
          width: 500px;
        }
      }
      .text-content-omics-pc {
        width: 475px;
        &.en {
          width: 624px;
        }
      }
      .text-content-interaction-pc {
        width: 475px;
        &.en {
          width: 624px;
        }
      }
      .text-content-literature-pc {
        width: 400px;
        &.en {
          width: 624px;
        }
      }
      p {
        opacity: 1;
        font-size: 24px;
        font-weight: normal;
        line-height: 40px;
        letter-spacing: -0.03em;
        font-variation-settings: 'opsz' auto;
        color: #283464;
        z-index: 0;
        &.en {
          font-size: 20px;
        }
        &.dark {
          color: #ffffff;
        }
      }

      .text-data-pc {
        opacity: 1;
        font-family: Alibaba-PuHuiTi-Bold;
        font-size: 110px;
        font-weight: 800;
        line-height: 32px;
        letter-spacing: -0.03em;
        font-variation-settings: 'opsz' auto;
        color: #283464;
        &.dark {
          color: #ffffff;
        }
      }

      .text-unit-pc {
        opacity: 1;
        font-family: Alibaba-PuHuiTi-Bold;
        font-size: 24px;
        font-weight: 800;
        line-height: 32px;
        letter-spacing: -0.03em;
        font-variation-settings: 'opsz' auto;
        font-feature-settings: 'kern' on;
        color: #283464;
        &.dark {
          color: #ffffff;
        }
      }

      .line-style-pc {
        opacity: 1;
        font-size: 24px;
        font-weight: normal;
        line-height: 40px;
        letter-spacing: 0em;
        font-variation-settings: 'opsz' auto;
        color: #283464;
        &.en {
          font-size: 20px;
        }
        &.dark {
          color: #ffffff;
        }
        li {
          margin-bottom: 32px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .img-content-pc {
      width: 749px;
      height: 468px;
      &.en {
        width: 600px;
      }
      .img-content-omics-pc {
        display: flex;
        width: 385px;
        height: 385px;
        border: none;
        border-radius: 28px;
        background: linear-gradient(
          134deg,
          rgba(255, 255, 255, 0.4) 3%,
          rgba(255, 255, 255, 0.85) 94%
        );
        opacity: 1;
        &.dark {
          background: linear-gradient(
            134deg,
            rgba(255, 255, 255, 0.8) 3%,
            rgba(255, 255, 255, 1) 94%
          );
        }

        img {
          height: auto;
          width: 120%;
        }
      }

      img {
        height: auto;
        width: 100%;
      }
    }
  }

  .layer-0 {
    animation-name: anim-layer-0;
    animation-timing-function: linear;
    animation-duration: 500ms;
    animation-fill-mode: both;
    animation-delay: 0s;
  }

  @keyframes anim-layer-0 {
    0% {
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}

:deep(.go-button-activate-pc) {
  color: #ffffff;
  border: none;
  background: linear-gradient(118deg, #598fff 11%, #1d62f1 97%);
  box-shadow: 4px 4px 10px 0px rgba(42, 108, 246, 0.35);

  padding: 10px 30px; /* 自定义内边距 */
  width: 170px; /* 自定义宽度 */
  height: 52px; /* 维持按钮原始高度 */
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
}

:deep(.go-button-activate-pc:hover) {
  color: #ffffff;
  border: none;
  background: linear-gradient(118deg, #598fff 11%, #1d62f1 97%);
  box-shadow: 4px 4px 10px 0px rgba(42, 108, 246, 0.35);
}

:deep(.go-button-no-activate-pc) {
  color: #646566;
  background: none;
  border: none;

  padding: 10px 30px;
  width: 170px;
  height: 52px;
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  &.dark {
    color: #ffffffb3;
  }
}

:deep(.go-button-no-activate-pc:hover) {
  color: #2a6cf6;
  background: none;
  border: none;
}
</style>
